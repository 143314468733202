<template>
  <div
    class="mask"
    :style="maskStyle"
  >
    <div v-if="text">
      {{ text }}
    </div>
    <div v-else>
      #{{ hex }}
    </div>
  </div>
</template>

<script>
import { isColorDark } from '@/helpers';

export default {
  name: 'MaskDisplay',
  components: {},
  props: {
    hex: String,
    text: String,
  },
  computed: {
    maskStyle() {
      if (!this.hex) return {};
      return {
        'background-color': `#${this.hex}`,
        color: isColorDark(this.hex) ? 'white' : 'black',
      };
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.mask {
  padding: 12px 24px;
  text-align: center;
  border: 1px solid #eee;
  font-family: monospace;
}
</style>
